const clickableBlock = (container: string, block: string) => {
    const itemListContainer: HTMLDivElement | null = document.querySelector(container);
    const itemList: NodeListOf<HTMLDivElement> | undefined = itemListContainer?.querySelectorAll(block);

    itemList?.forEach((item: HTMLDivElement) => {
        item.addEventListener('click', (e: Event) => {
            const isLink = e.target instanceof HTMLAnchorElement;
            const profileUrl = item.dataset.url;
            const isFavoritesMenu = e.target instanceof HTMLDivElement && e.target.classList.contains('js-add-favorite');

            if (!isLink && !isFavoritesMenu && profileUrl) {
                window.location.href = profileUrl;
            }
        });
    });
};

export default clickableBlock;
