const trackTypeOfView = () => {
    const currentViewType = document.querySelector<HTMLElement>('.js-wio__hd')?.dataset.viewType;

    if (currentViewType !== undefined) {
        Analytics.sendEvent({
            category: 'View type',
            action: currentViewType,
            label: window.location.href,
        });
    }
};

export default trackTypeOfView;
