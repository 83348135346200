import popoutMenu from '../features/popout-menu';
import { popoutMenuActionType } from '../typings';

export default function attachPopoutMenu(selector: string, popoutSelector: string, actionType: popoutMenuActionType): void {
    const popoutContainers: NodeListOf<HTMLDivElement> = document.querySelectorAll(selector);

    if (popoutContainers.length) {
        popoutContainers.forEach((popoutContainer: HTMLDivElement) => {
            const popoutElement: HTMLDivElement | null = popoutContainer.querySelector(popoutSelector);

            if (popoutElement) {
                popoutMenu(popoutContainer, popoutElement, actionType);
            }
        });
    }
}
