import { IS_VISIBLE, FLOATING_CTA_DELAY } from '../constants';

export default function registerFloatingCTA(element: string) {
    const elm: HTMLDivElement | null = document.querySelector(element);

    if (elm) {
        setTimeout(() => {
            elm.classList.add(IS_VISIBLE);
        }, FLOATING_CTA_DELAY);
    }
}
